.App {
  display: flex;
  flex-direction: row;
}

.App.dark{
  filter: invert(0.9);
  background: #f9f9f9;
}

.app-dashboard-sidebar{
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-right: 1px solid #00000030;
  box-shadow: 0 0 20px 5px #ffffff;
}

.app-dashboard-sidebar-container{
  width: 250px;
}

.app-dashboard-body{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: center;   */
}

.app-dashboard-body-topbar{
  color: #000;
  font-size: 20px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  font-weight: 500;
  padding: 10px 20px;
}

.departures{
  background: #ff4848;
  padding: 10px 20px 0px;
  display: inline-block;
  border-radius: 25px;
  line-height: 1.1;
  border: 2px solid #dd0000;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  color: #fff;
  margin: auto;
  height: 40px;
}

.app-dashboard-body-display{
  height: 100%;
}

.warning{
  position: relative;
}

.warning:before{
  content: "";
  top: 8px;
  left: -10px;
  padding: 3px;
  border-radius: 5px;
  position: absolute;
  background: red;
}

/* CUSTOME SIDEBAR CARD */
.app-dashboard{
  overflow: hidden;
}

.app-dashboard-card-color-lable::before{
  content: "";
  padding: 3px;
  position: absolute;
  right: 10px;
  top: 10px;
  bottom: 10px;
  
  /* width: 45px;*/
  border-radius: 20px; 
}

.app-dashboard-card-color-lable:hover::before{
  animation: blink-animation 500ms steps(5, start) infinite;
}

.app-dashboard-card-color-lable.red::before{
  background-color: #FF004E;
  /* background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,#FF004E 50%,rgba(255,255,255,0) 100%); */
}

.app-dashboard-card-color-lable.green::before{
  background-color: #00FF90;
  /* background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,#00FF90 50%,rgba(255,255,255,0) 100%); */
}