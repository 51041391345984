/* Loading */

.alert-loading-container{
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 10px;
    background: #ff004e;
    z-index: 100;
    border-radius: 10px;
}