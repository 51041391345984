.svg-map-container{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    background-image: url("./map.svg");
    margin: 10px 0;
}

.color-indicator{
    padding: 2px;
}

.vector-map-citation{
    position: absolute;
    top: 5px;
    right: 5px;
}